@import '@/core/tamagoshi/scss/colors';
@import '@/core/tamagoshi/scss/mixins';
@import '@/core/tamagoshi/scss/variables';

.badge {
  border-radius: 4px;
  padding: $spacingXXs $spacingXs;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;

  :first-child {
    margin-left: $spacingXXs;
  }

  &.accent {
    background-color: $b400;
    color: $n0;
  }

  &.green {
    background-color: $g900;
    color: $n0;
  }

  &.light {
    background-color: $n0;
    color: $b400;
  }

  &.sponsor {
    background-color: $n0;
    border: 2px solid $b400;
    color: $b400;
  }

  &.medium {
    @include text('body2');
  }

  &.small {
    @include text('small');
  }
}
