@use '/src/domains/core/tamagoshiv2/tokens/colors';
@use '/src/domains/core/tamagoshiv2/tokens/spacings';
@use '/src/domains/core/tamagoshiv2/tokens/radius';

@import '@/core/tamagoshi/scss/colors';
@import '@/core/tamagoshi/scss/mixins';
@import '@/core/tamagoshi/scss/variables';
@import '@/domains/productDiscovery/Listings/styles/layout';

.footer {
  align-items: center;
  align-self: flex-end;
  display: flex;
  padding-bottom: spacings.$m;
  text-align: right;

  .icon {
    $size: spacings.$m;
    height: $size;
    margin-left: spacings.$s;
    width: $size;
  }
}

.header {
  position: relative;
  // Offset Tailwind's reset that overrides the inherited image height
  height: 164px;
}

.sponsoredLabel.sponsoredLabel {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba($blackColor, 0.24);
  margin: 0;
  padding-right: 0;
  padding-left: spacings.$xs;
  border-radius: 0 calc(#{radius.$container} / 2) 0 radius.$container;

  > span {
    padding-right: spacings.$xs;
  }

  span,
  svg {
    color: colors.$foundation-textIconInverted;
  }
}

.sponsorPopover.sponsorPopover {
  width: max-content;

  span {
    color: inherit;
  }
}

.title {
  // stylelint-disable-next-line value-no-vendor-prefix
  display: -webkit-box;
  margin-bottom: auto;
  margin-top: auto;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.root {
  $shadowColor: rgba($blackColor, 0.08);
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
  box-shadow: 0 2px 6px 0 $shadowColor;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 215px;
  text-decoration: none;
  transition: box-shadow 0.3s ease;

  @media (hover) {
    &:hover {
      box-shadow: 0 8px 12px 0 $shadowColor;

      .footer {
        text-decoration: underline;
      }
    }
  }

  &.accent {
    $background: $b400;
    background-color: $background;
    border-color: $background;

    .footer,
    .title {
      color: $n0;
    }
  }

  &.green {
    background-color: $n0;
    border-color: $g800;
  }

  &.light {
    background-color: $n0;
    border-color: $b400;
  }

  &.sponsor {
    background-color: $n0;
    border-color: $b400;
  }
}

.body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: $spacingSm spacings.$m 0;
  position: relative;
  height: 164px;
  color: $n800;

  .tag {
    @include text('small', 'semiBold');
    left: $spacingSm;
    position: absolute;
    top: 0;
    transform: translateY(-50%);
  }
}

.image {
  display: block;
  object-fit: cover;
  width: 100%;
  border-radius: 0 calc(#{radius.$container} / 2) calc(#{radius.$container} / 2)
    0;
  height: inherit;
}

.root.horizontal {
  min-height: auto;

  .title {
    // stylelint-disable-next-line value-no-vendor-prefix
    display: -webkit-box;
    -webkit-line-clamp: 4;
  }

  @include size-above($fourColumnsMobile) {
    height: 180px;
    flex-direction: row;

    .header {
      order: 2;
      width: 50%;
      height: 100%;
    }

    .body {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      width: 50%;
      height: 100%;
    }

    .tag {
      position: static;
      transform: unset;
    }

    .title {
      // stylelint-disable-next-line value-no-vendor-prefix
      display: -webkit-box;
      -webkit-line-clamp: 3;
    }
  }
}
